import Vue from 'vue'
import App from './App.vue'

import "@/css/styles.css"
import "@/js/scripts.js"

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
