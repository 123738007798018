<template>
  <div id="app">
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
        <div class="container">
            <a href="#page-top"><img  class="navbar-brand" src="./assets/img/navbar-logo.png" alt="Gotingeen" /></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                Menú
                <i class="fas fa-bars ms-1"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                    <li class="nav-item"><a class="nav-link" href="#head">Inicio</a></li>
                    <li class="nav-item"><a class="nav-link" href="#services">Servicios</a></li>
                    <li class="nav-item"><a class="nav-link" href="#about">Nosotros</a></li>
                    <li class="nav-item"><a class="nav-link" href="#contact">Contacto</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Masthead-->
    <header class="masthead" id="head">
        <div class="container">
            <div class="masthead-subheading">¡Globalizamos tus Ideas!</div>
            <div class="masthead-heading text-uppercase">Logística Avanzada Gotingeen</div>
            <a class="btn btn-primary btn-xl text-uppercase" href="#services">Saber Más</a>
        </div>
    </header>
    <!-- Services-->
    <section class="page-section" id="services">
        <div class="container">
            <div class="text-center">
                <h2 class="section-heading text-uppercase">Nuestros Servicios</h2>
                <h3 class="section-subheading text-muted">Ofrecemos Consultoría y Soluciones para tu Negocio</h3>
            </div>
            <div class="row text-center">
                <div class="col-md-4">
                    <span class="fa-stack fa-4x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-box-open fa-stack-1x fa-inverse"></i>
                    </span>
                    <h4 class="my-3">Avaluos de Mercancía</h4>
                    <p class="text-muted">Blindamos de manera segura el valor declarado en temas mercantiles, para que tus productos no corran riesgo de ser revisados o inspeccionados por la autoridad pertinente.</p>
                </div>
                <div class="col-md-4">
                    <span class="fa-stack fa-4x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fas fa-clipboard-list fa-stack-1x fa-inverse"></i>
                    </span>
                    <h4 class="my-3">Inventarios</h4>
                    <p class="text-muted">Estructuramos un sistema de almacén para llevar el monitoreo, medición y control de todo tu producto y no tengas exceso de inventarios, así como retrasos de entregas.</p>
                </div>
                <div class="col-md-4">
                    <span class="fa-stack fa-4x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-medal fa-stack-1x fa-inverse"></i>
                    </span>
                    <h4 class="my-3">Peritos en General</h4>
                    <p class="text-muted">Contamos con amplia gama de expertos en la materia que tú requieres y que se adapte a tus necesidades.</p>
                </div>
            </div>
        </div>
    </section>
    <!-- About-->
    <section class="page-section bg-light" id="about">
        <div class="container">
            <div class="text-center">
                <h2 class="section-heading text-uppercase">Nosotros</h2>
                <h3 class="section-subheading text-muted">Colaboramos con empresas de distintos sectores y tamaños.</h3>
            </div>
            <ul class="timeline">
                <li>
                    <div class="timeline-image"><img class="rounded-circle img-fluid" src="./assets/img/about/1.jpg" alt="..." /></div>
                    <div class="timeline-panel">
                        <div class="timeline-heading">
                            <h4 class="subheading">Misión</h4>
                        </div>
                        <div class="timeline-body"><p class="text-muted">Ofrecer un servicio integral sincronizado, conformado por expertos en determinadas materias, que cubra una amplia variedad de necesidades de nuestros clientes, agregando valor a cada uno de sus procesos.</p></div>
                    </div>
                </li>
                <li class="timeline-inverted">
                    <div class="timeline-image"><img class="rounded-circle img-fluid" src="./assets/img/about/2.jpg" alt="..." /></div>
                    <div class="timeline-panel">
                        <div class="timeline-heading">
                            <h4 class="subheading">Visión</h4>
                        </div>
                        <div class="timeline-body"><p class="text-muted">Llegar a cada variedad de mercado, que cumpla con las exigencias demandantes de la industria proporcionando un servicio con los más altos estándares de cumplimiento y calidad.</p></div>
                    </div>
                </li>
                <li class="timeline-inverted">
                    <div class="timeline-image">
                        <h4>
                            ¡Podemos
                            <br />
                            Ayudarte!
                            <br />
                            :)
                        </h4>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    <!-- Contact-->
    <section class="page-section" id="contact">
        <div class="container">
            <div class="text-center">
                <h2 class="section-heading text-uppercase">¡Contactanos!</h2>
            </div>
            <FormEmailJs/>
        </div>
    </section>
    <!-- Footer-->
    <footer class="footer py-4">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 text-lg-start">Copyright &copy; Logística Avanzada Gotingeen. <br> Av. Ignacio L. Vallarta 1525, Col Americana, Lafayette, 44160 Guadalajara, Jal.</div>
            </div>
        </div>
    </footer>
    <!-- Bootstrap core JS-->
  </div>
</template>

<script>
import FormEmailJs from './components/FormEmailJs.vue'

export default {
  name: 'App',
  components: {
    FormEmailJs
  }
}
</script>

