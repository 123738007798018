<template>
  <form id="contactForm" @submit.prevent="sendEmail">
    <input name="to_name" type="hidden" value="Gotingeen">
    <input name="to_host" type="hidden" value="contacto@gottingen.com.mx">
    <div class="row align-items-stretch mb-5">
        <div class="col-md-4">
            <div class="form-group">
                <!-- Name input-->
                <input class="form-control" name="from_name" id="name" type="text" placeholder="Tu Nombre *" required />
                <div class="invalid-feedback" data-sb-feedback="name:required">El nombre es requerido.</div>
            </div>
            <div class="form-group">
                <!-- Email address input-->
                <input class="form-control" id="email" name="reply_to" type="email" placeholder="Tu Correo *" required/>
                <div class="invalid-feedback" data-sb-feedback="email:required">El correo es requerido.</div>
                <div class="invalid-feedback" data-sb-feedback="email:email">El correo no es válido.</div>
            </div>
            <div class="form-group mb-md-0">
                <!-- Phone number input-->
                <input class="form-control" id="phone" type="tel" placeholder="Tu Teléfono *" required/>
                <div class="invalid-feedback" data-sb-feedback="phone:required">El número de teléfono es requerido.</div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group form-group-textarea mb-md-0">
                <!-- Message input-->
                <textarea class="form-control" id="message" name="message" placeholder="Tu Mensaje *" required></textarea>
                <div class="invalid-feedback" data-sb-feedback="message:required">El mensaje es requerido.</div>
            </div>
        </div>
        <div class="col-md-4">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1866.442336428132!2d-103.3710202009336!3d20.674270438795038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae055fb046cb%3A0x566fdf64157bc45b!2sAv.%20Ignacio%20L.%20Vallarta%201525%2C%20Col%20Americana%2C%20Lafayette%2C%2044160%20Guadalajara%2C%20Jal.!5e0!3m2!1ses!2smx!4v1657922886262!5m2!1ses!2smx"
            width="350" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
    <div class="text-center"><input class="btn btn-primary btn-xl text-uppercase" id="sendBtn" type="submit" value="Enviar Mensaje"></div>
  </form>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  methods: {
    sendEmail: (e) => {
      const btn = document.getElementById("sendBtn");
      btn.value = "Enviando...";
      emailjs.sendForm('service_q2zlk5g', 'template_lnf44bf', e.target, 'user_iv7NEVHX3p6AihGpfuzp6')
        .then((result) => {
            btn.value = "¡Enviado!";
            console.log('¡Mensaje Enviado!', result.status, result.text);
        }, (error) => {
            btn.value = "Intente de nuevo";
            console.log('Error el enviar mensaje...', error);
        });
    }
  }
}
</script>